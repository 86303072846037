import { APP_URL, api } from '.';
import {
  ATTACHMENT,
  DATA_PROTECTION,
  FormValues,
} from 'components/helpForm';
import {
  Company,
  CountryFromAPI,
  Device,
  FileType,
  PTO,
  PayWindow,
  PayWindowGroup,
} from 'types/appTypes';

import { AxiosError } from 'axios';
import { GATEWAY } from 'localStorage/localStorageKeys';
import { errorHandling } from 'utils/funcs/errorHandling';
import { loadFromLocalStorage } from 'localStorage';
import { normalizeCountries } from 'utils/funcs/transform';

export interface PaginatedData<T> {
  data: T;
  count: number;
  limit: number;
  offset: number;
  next_page: boolean;
}

const getPtoList = async () => {
  try {
    const { data } = await api.get<PTO[]>('/pto');
    return data;
  } catch (e) {
    errorHandling(e);
  }
};

const getPto = async (id: string) => {
  try {
    const { data } = await api.get<PTO>(`/pto/${id}`);
    return data;
  } catch (e) {
    errorHandling(e);
  }
};

const getLastPW = async () => {
  try {
    const { data } = await api.get<PaginatedData<PayWindowGroup[]>>(
      `/paywindow/last`,
    );
    return data;
  } catch (e) {
    errorHandling(e);
  }
};

const getPayWindows = async (showDebt = false, params = {}) => {
  if (showDebt) {
    params = { ...params, status: 'debt' };
  }

  try {
    const { data } = await api.get<PaginatedData<PayWindowGroup[]>>(
      `/paywindow`,
      {
        params,
      },
    );
    return data;
  } catch (e) {
    errorHandling(e);
    throw new Error('different error than axios');
  }
};

const getRideReceipt = async (
  type: FileType,
  id: string,
  name: string,
) => {
  try {
    const token = await getDownloadToken();
    const link = document.createElement('a');
    link.href = `${APP_URL}/ride/export/${type}/${id}/${token}`;
    link.setAttribute('download', `${name}.${type}`);
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    errorHandling(e);
  }
};

const getPayWindowReceipt = async (
  type: FileType,
  id: string,
  name: string,
  options?: string,
) => {
  try {
    const token = await getDownloadToken();
    const link = document.createElement('a');
    link.href = `${APP_URL}/paywindow/export/${type}/${id}/${token}${
      options ? `?${options}` : ''
    }`;
    link.setAttribute('download', `${name}.${type}`);
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    errorHandling(e);
  }
};

const getReceiptsForMultiplePayWindows = async (
  name: string,
  type: 'csv' | 'pdf',
  params: { from: string; to: string },
) => {
  try {
    const token = await getDownloadToken();
    const link = document.createElement('a');
    link.href = `${APP_URL}/paywindow/export/${type}/${token}?from=${params.from}&to=${params.to}`;
    link.setAttribute('download', `${name}.${type}`);
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    errorHandling(e);
  }
};

const getPayWindowInvoice = async (
  payWindowId: string,
  type: 'private' | 'domestic' | 'foreign',
  fields: any, // eslint-disable-line @typescript-eslint/no-explicit-any
) => {
  try {
    const token = await getDownloadToken();
    const result = await api.post(
      `/paywindow/export/invoice/pdf/${payWindowId}/${token}`,
      { ...fields, type },
    );
    return result;
  } catch (e) {
    errorHandling(e);
    const error = e as AxiosError;
    return error;
  }
};

const getJourneyInvoice = async (
  journeyId: string,
  type: 'private' | 'domestic' | 'foreign',
  fields: any, // eslint-disable-line @typescript-eslint/no-explicit-any
) => {
  try {
    const token = await getDownloadToken();
    const result = await api.post(
      `/ride/export/invoice/pdf/${journeyId}/${token}`,
      { ...fields, type },
    );
    return result;
  } catch (e) {
    errorHandling(e);
    const error = e as AxiosError;
    return error;
  }
};

const postClearPayWindowDebt = async (id: string) => {
  try {
    const { data } = await api.post<PayWindow>(
      `/paywindow/clear/${id}`,
    );
    return data;
  } catch (e) {
    errorHandling(e);
  }
};

const postClearAllPayWindowDebt = async () => {
  try {
    const { data } = await api.post<{ objects: PayWindow[] }>(
      `/paywindow/clear`,
    );
    return data;
  } catch (e) {
    errorHandling(e);
  }
};

const appendData = (formData: FormData, data: FormValues) => {
  Object.entries(data).forEach(([key, value]) => {
    if (!value || key === DATA_PROTECTION) {
      return;
    }

    if (key === ATTACHMENT) {
      formData.append(`${key}[]`, value as File);
      return;
    }

    formData.append(key, value as string | File);
  });
};

const reportProblem = async (data: FormValues, options?: string) => {
  const formData = new FormData();
  appendData(formData, data);

  try {
    const response = await api.post<PayWindow>(
      `/mailer/reportproblem${options ? `?${options}` : ''}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return response;
  } catch (e) {
    errorHandling(e);
  }
};

const getDevicesList = async () => {
  try {
    const { data } = await api.get<Device[]>('/device');
    return data;
  } catch (e) {
    errorHandling(e);
    throw new Error('different error than axios');
  }
};

const getDevice = async (id: string) => {
  try {
    const { data } = await api.get<Device>(`/device/${id}`);
    return data;
  } catch (e) {
    errorHandling(e);
  }
};

const saveEditDevice = async (
  id: string,
  values: { name: string; type: string },
) => {
  try {
    const { data } = await api.patch<Device>(`/device/${id}`, values);
    return data;
  } catch (e) {
    errorHandling(e);
  }
};

const getDownloadToken = async () => {
  try {
    const {
      data: { token },
    } = await api.get<{ token: string }>('/token/onetime');

    return token;
  } catch (e) {
    errorHandling(e);
  }
};

const getCountries = async () => {
  try {
    const { data } = await api.get<CountryFromAPI[]>('/countries');

    return normalizeCountries(data);
  } catch (e) {
    errorHandling(e);
  }
};

const getCompanyData = async (taxId: string) => {
  try {
    const { data } = await api.get<Company>(`/invoice_data/${taxId}`);

    return data;
  } catch (e) {
    errorHandling(e);
  }
};

const getDataProtectionDocument = async () => {
  const gateway = loadFromLocalStorage({ key: GATEWAY });

  try {
    const response = await api.get<Blob>(
      `/documents/dataprotection${
        gateway ? `?gateway=${gateway}` : ''
      }`,
      { responseType: 'blob' },
    );
    return response;
  } catch (e) {
    errorHandling(e);
  }
};

export {
  getPtoList,
  getPto,
  getLastPW,
  getPayWindows,
  getRideReceipt,
  getPayWindowInvoice,
  getJourneyInvoice,
  getPayWindowReceipt,
  postClearPayWindowDebt,
  reportProblem,
  getDevicesList,
  getDevice,
  postClearAllPayWindowDebt,
  saveEditDevice,
  getReceiptsForMultiplePayWindows,
  getDownloadToken,
  getCountries,
  getCompanyData,
  getDataProtectionDocument,
};
