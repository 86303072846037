import AdapterDateFns from '@mui/lab/AdapterDateFns';
import AppRoutes from './appRoutes';
import { EventHandler } from 'components/eventHandler';
// import { GATEWAY } from 'localStorage/localStorageKeys';
import { LocalizationProvider } from '@mui/lab';
import { SnackbarProvider } from 'notistack';
import cs from 'date-fns/locale/cs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-US';
import hu from 'date-fns/locale/hu';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import sk from 'date-fns/locale/sk';
// import { loadFromLocalStorage } from 'localStorage';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function AppLocalizer() {
  const { i18n } = useTranslation();
  // const gateway = loadFromLocalStorage({ key: GATEWAY });

  const getLocaleSettings = () => {
    switch (i18n.resolvedLanguage) {
      case 'en':
        return en;
      case 'cs':
        return cs;
      case 'sk':
        return sk;
      case 'de':
        return de;
      case 'hu':
        return hu;
      default:
        return en;
    }
  };

  dayjs.extend(isSameOrBefore);
  dayjs.extend(isSameOrAfter);
  dayjs.extend(customParseFormat);

  useEffect(() => {
    if (i18n.resolvedLanguage === 'hu') {
      document.title = 'Ügyfélportál BP Pay&GO';
    } else {
      document.title = 'Customer portal';
    }
  }, []);

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      locale={getLocaleSettings()}
    >
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        style={{
          height: 100,
          width: 475,
          flexGrow: 0,
          fontSize: '24px',
        }}
      >
        <EventHandler />
        <AppRoutes />
      </SnackbarProvider>
    </LocalizationProvider>
  );
}
