import { Typography, styled, useTheme } from '@mui/material';
import {
  formatDateToDay,
  formatDateToTime,
} from '../utils/funcs/formatDate';
import { useCallback, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import { Device } from 'types/appTypes';
import EditDeviceDialog from '../components/editDeviceDialog';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import OverviewHeader from '../components/overview/overviewHeader';
import { formatIntegerToLocalString } from 'utils/funcs/formatNumber';
import { getDevice } from 'api';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

const DeviceDetailInfoWrap = styled(Box)(
  ({ theme: { breakpoints, spacing, palette } }) => ({
    marginTop: spacing(4),
    padding: spacing(5, 4),
    background: palette.background.paper,
    borderRadius: spacing(1),
    [breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
);

const DeviceDetailItem = styled(Box)(
  ({ theme: { breakpoints, spacing } }) => ({
    minWidth: '10%',
    gap: spacing(0, 2),
    marginBottom: spacing(2),
    [breakpoints.up('md')]: {
      marginBottom: 0,
    },
  }),
);

const Value = styled(Typography)(({ theme: { palette } }) => ({
  fontWeight: 'bold',
  color: palette.text.darkBlue.main,
}));

const Title = styled(Typography)(({ theme: { palette } }) => ({
  color: palette.text.lightGrey.main,
}));

const DeviceDetailPage: React.FC = () => {
  const { t } = useTranslation('common', {
    keyPrefix: 'pages.device',
  });

  const theme = useTheme();
  const { id } = useParams();
  const [data, setData] = useState<Device | null>(null);

  useEffect(() => {
    if (id) loadData(id);
  }, []);

  const loadData = async (id: string) => {
    const result = await getDevice(id);
    if (result) setData(result);
  };

  const [openDeviceDialog, setOpenDeviceDialog] = useState(false);

  const handleClickOpenEditDeviceDialog = () => {
    setOpenDeviceDialog(true);
  };

  const handleCloseDeviceDialog = () => {
    setOpenDeviceDialog(false);
  };

  const handleSaveDeviceDialog = (updatedDevice: Device) => {
    setOpenDeviceDialog(false);
    setData(updatedDevice);
  };

  const createDeviceDetailInfo = useCallback(
    (title: string, value: string | Date) => {
      return (
        <DeviceDetailItem>
          <Title variant="caption">{t(title)}</Title>
          <Value>{value}</Value>
        </DeviceDetailItem>
      );
    },
    [],
  );

  return (
    <>
      {!!data && (
        <>
          <OverviewHeader
            boldInfo={data.name}
            actions={[
              {
                icon: <EditOutlinedIcon />,
                label: t('detail.edit'),
                onClick: handleClickOpenEditDeviceDialog,
              },
            ]}
          />
          <DeviceDetailInfoWrap>
            {createDeviceDetailInfo('detail.pan', data.pan)}
            {createDeviceDetailInfo(
              'detail.lastUsed',
              data.last_used &&
                `${formatDateToDay(
                  data.last_used,
                )} | ${formatDateToTime(data.last_used)}`,
            )}
            {createDeviceDetailInfo('detail.discount', data.discount)}
            {!!data.balance &&
              !!data.currency &&
              createDeviceDetailInfo(
                'detail.balance',
                `
                ${formatIntegerToLocalString(data.balance / 100)}
                ${data.currency}
                `,
              )}
            {createDeviceDetailInfo(
              'detail.type',
              t(`type.${data.type}`),
            )}
            <DeviceDetailItem>
              <Title variant="caption">{t('detail.status')}</Title>
              <Value
                sx={{
                  color:
                    data.status === 'active'
                      ? theme.palette.success.main
                      : theme.palette.error.main,
                }}
              >
                {t(`status.${data.status}`)}
              </Value>
            </DeviceDetailItem>
          </DeviceDetailInfoWrap>
          <EditDeviceDialog
            device={data}
            isOpen={openDeviceDialog}
            closeFunction={handleCloseDeviceDialog}
            saveFunction={(updatedDevice: Device) =>
              handleSaveDeviceDialog(updatedDevice)
            }
          />
        </>
      )}
    </>
  );
};

export default DeviceDetailPage;
