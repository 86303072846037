import { Device, PayWindowGroup } from '../types/appTypes';
import {
  getDevicesList,
  getPayWindows,
  postClearAllPayWindowDebt,
} from '../api';
import { styled, useTheme } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';

import DeviceBox from '../components/deviceBox';
import Lodash from 'lodash';
import OverviewHeader from 'components/overview/overviewHeader';
import { PayWindowBox } from './ridesPage';
import { useTranslation } from 'react-i18next';

const DevicesWrapper = styled('div')(({ theme: { spacing } }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
  gap: spacing(4),
}));

interface DeviceState {
  devices: Device[];
  payWindowGroups: PayWindowGroup[];
}

const DevicesPage = () => {
  const theme = useTheme();
  const [data, setData] = useState<DeviceState>({
    devices: [],
    payWindowGroups: [],
  });

  const { t } = useTranslation('common', {
    keyPrefix: 'pages.device',
  });

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const result = await Promise.all([
      getDevicesList(),
      getPayWindows(true),
    ]);
    setData({ devices: result[0], payWindowGroups: result[1].data });
  };

  const debtActions = useMemo(() => {
    if (data.payWindowGroups.length > 0)
      return [
        {
          disabled: data.payWindowGroups.length === 0,
          icon: '',
          label: t('clearAllDebts'),
          onClick: async () =>
            await postClearAllPayWindowDebt().then(async () => {
              await loadData();
            }),
        },
      ];
    return undefined;
  }, [data.payWindowGroups, t]);

  return (
    <>
      <div style={{ marginBottom: theme.spacing(4) }}>
        <OverviewHeader boldInfo={t(`pageTitle`)} />
      </div>
      <DevicesWrapper>
        {data.devices.map((device, index) => (
          <DeviceBox
            key={index}
            id={device.id}
            deviceNumber={device.pan}
            name={device.name}
            status={device.status}
            type={device.type}
            balance={device.balance ?? 0}
            currency={device.currency ?? null}
          />
        ))}
      </DevicesWrapper>
      <div
        style={{
          marginBottom: theme.spacing(4),
          marginTop: theme.spacing(4),
        }}
      >
        {data.payWindowGroups.length > 0 && (
          <OverviewHeader
            boldInfo={t(`debtsTitle`)}
            actions={debtActions}
          />
        )}
        {data.payWindowGroups.map((payWindowGroup) => {
          return payWindowGroup.payWindows.map((paywindow, index) => (
            <PayWindowBox
              key={index}
              payWindow={paywindow}
              clearPayWindowCallBack={(
                payWindowToReplaceWith,
                sortingFunction,
              ) => {
                if (data.payWindowGroups) {
                  const newPayWindowGroups = sortingFunction(
                    payWindowToReplaceWith,
                    data.payWindowGroups,
                  );

                  const newState = Lodash.cloneDeep(data);
                  setData({
                    ...newState,
                    payWindowGroups: newPayWindowGroups,
                  });
                }
              }}
            />
          ));
        })}
      </div>
    </>
  );
};

export default DevicesPage;
