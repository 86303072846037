import { Box, Typography, styled } from '@mui/material';
import { DeviceStatus, DeviceTypes } from 'types/appTypes';

import ActionButton from '../actionButton';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { DEVICES_DETAIL_URL } from '../../pages/appRoutesConst';
import { NoticeIcon } from '../noticeIcon';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import WatchIcon from '@mui/icons-material/Watch';
import { formatIntegerToLocalString } from 'utils/funcs/formatNumber';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

interface DeviceBoxProps {
  id: string;
  type: DeviceTypes;
  name: string;
  deviceNumber: string;
  status: DeviceStatus;
  balance: number;
  currency: string;
}

interface DeviceTypeProps {
  type: DeviceBoxProps['type'];
}

const DeviceTypeIcon: React.FC<DeviceTypeProps> = ({ type }) => {
  const iconStyle = { height: '80px', width: '80px' };
  let icon = null;

  switch (type) {
    case 'Card':
      icon = (
        <CreditCardIcon color="secondary" sx={{ ...iconStyle }} />
      );
      break;
    case 'Smartphone':
      icon = (
        <SmartphoneIcon color="secondary" sx={{ ...iconStyle }} />
      );
      break;
    case 'Smartwatch':
      icon = <WatchIcon color="secondary" sx={{ ...iconStyle }} />;
      break;
    default:
      break;
  }

  return <>{icon}</>;
};

const DeviceName = styled(Typography)(({ theme: { spacing } }) => ({
  marginTop: spacing(3),
  marginBottom: spacing(2),
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  maxWidth: '100%',
}));

const DeviceBalance = styled(Typography)(
  ({ theme: { spacing } }) => ({
    marginTop: spacing(1),
    marginBottom: spacing(1),
  }),
);

const DeviceBoxWrapper = styled(Box)(({ theme: { palette } }) => ({
  position: 'relative',
  backgroundColor: palette.background.paper,
  height: '360px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  borderRadius: 8,
  overflow: 'hidden',
}));

const InfoWrapper = styled('div')(({ theme: { spacing } }) => ({
  flexDirection: 'column',
  alignItems: 'center',
  display: 'flex',
  padding: spacing(7, 5, 3, 5),
}));

const ActionButtonWrapper = styled('div')(
  ({ theme: { spacing } }) => ({
    position: 'absolute',
    top: spacing(2),
    right: spacing(2),
  }),
);

const StatusWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== status,
})<{ status: 'error' | 'success' }>(
  ({ theme: { palette }, status }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor:
      status === 'success'
        ? palette.success.light
        : palette.error.light,
    width: '100%',
    height: '64px',
  }),
);

const CardNumber = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.lightGrey.main,
}));

const DeviceBox: React.FC<DeviceBoxProps> = ({
  id,
  type,
  name,
  deviceNumber,
  status,
  balance,
  currency,
}) => {
  const { t } = useTranslation('common', {
    keyPrefix: 'pages.device.status',
  });
  const navigate = useNavigate();
  const statusTransform = status === 'active' ? 'success' : 'error';
  return (
    <DeviceBoxWrapper>
      <InfoWrapper>
        <ActionButtonWrapper>
          <ActionButton
            menuItems={[
              {
                label: t('detail'),
                onClick: () =>
                  navigate(`${DEVICES_DETAIL_URL}/${id}`),
              },
            ]}
          />
        </ActionButtonWrapper>
        <DeviceTypeIcon type={type} />
        <DeviceName variant="h5">{name}</DeviceName>
        <CardNumber>{deviceNumber}</CardNumber>
        {!!currency && !!balance && (
          <DeviceBalance>{`${formatIntegerToLocalString(
            balance / 100,
          )} ${currency}`}</DeviceBalance>
        )}
      </InfoWrapper>
      <StatusWrapper status={statusTransform}>
        <Typography>
          <NoticeIcon type={statusTransform} label={t(status)} />
        </Typography>
      </StatusWrapper>
    </DeviceBoxWrapper>
  );
};

export default DeviceBox;
